/* App.css */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.container {
  max-width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px; /* Add spacing between the two input boxes */
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  font-size: 18px;
  border: 2px solid #aaa;
  border-radius: 5px;
  outline: none;
}

input[type="date"] {
  padding: 10px;
  font-size: 18px;
  border: 2px solid #aaa;
  border-radius: 5px;
  outline: none;
}

input {
  flex-grow: 1;
  padding: 10px;
  font-size: 18px;
  border: 2px solid #aaa;
  border-radius: 5px;
  outline: none;
}

input:focus {
  border-color: #66afe9;
}

button {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:active {
  background-color: #004085;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f0f2f5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

li > span {
  font-size: 18px;
  flex: 1;
  word-wrap: break-word;
  max-width: 70%;
}


li > div {
  display: flex;
  gap: 10px;
}

.social-media {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-media a {
  color: #333;
  margin: 0 10px;
  font-size: 24px;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #007bff;
}

.dark-mode {
  background-color: #333;
  color: white;
}

.dark-mode input,
.dark-mode button {
  border-color: white;
  background-color: #474a50;
  color: white;
}

.dark-mode input:focus {
  border-color: #66afe9;
}

.dark-mode ul li {
  background-color: #202225;
}

.dark-mode .social-media a {
  color: white;
}

.dark-mode .social-media a:hover {
  color: #007bff;
}

/* Add this rule to change the body background color in dark mode */
body.dark-mode {
  background-color: #333;
}

/* Add this rule to change the title color in dark mode */
.dark-mode h1 {
  color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark-mode {
  background-color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.dark-mode {
  background-color: rgb(40, 43, 47);
  color: white;
}

.dark-mode h1 {
  color: white;
}

.dark-mode-switch {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.9;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  animation: loadingEffect 2s linear;
}

@keyframes loadingEffect {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

nav {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 20px;
  cursor: pointer;
}

nav li.active {
  font-weight: bold;
}

.warning-box {
  background-color: #ffcc00;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dark-mode .warning-box {
  background-color: #ffe680;
  color: #333;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-buttons button:hover {
  background-color: #f0f0f0;
}

.modal-buttons button:active {
  background-color: #ddd;
}

/* Dark mode styles */
.dark-mode .modal {
  background-color: #474a50;
  color: white;
}

.dark-mode .modal-buttons button {
  background-color: #666;
  color: white;
}

.dark-mode .modal-buttons button:hover {
  background-color: #888;
}

.dark-mode .modal-buttons button:active {
  background-color: #555;
}

/* Add transition to body */
body {
  transition: background-color 0.7s, color 0.7s;
}

/* Add transition to container */
.container {
  transition: background-color 0.7s;
}

/* Add transition to h1 */
h1 {
  transition: color 0.7s;
}

/* Add transition to form inputs and buttons */
input,
button {
  transition: background-color 0.7s, border-color 0.7s, color 0.7s;
}

/* Add transition to list items */
ul li {
  transition: background-color 0.7s, color 0.7s;
}

/* Add transition to social media links */
.social-media a {
  transition: color 0.7s;
}

/* Add transition to warning boxes */
.warning-box {
  transition: background-color 0.7s, color 0.7s;
}

/* Add transition to modal and its buttons */
.modal,
.modal-buttons button {
  transition: background-color 0.7s, color 0.7s;
}


